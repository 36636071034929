<template>
  <div
    v-memo="slices"
    class="content-types"
  >
    <component
      :is="getSliceComponent(slice)"
      v-for="(slice, index) in slices"
      :key="index"
      :slice="slice"
      :index="index"
      :slices="slices"
      class="content-type"
      :class="getSliceClass(slice)"
      :style="`--bg-color: ${slice?.Settings?.BackgroundColor || 'transparent'};`"
    />

    <!--  -->
  </div>
</template>

<script setup lang="ts">
defineProps({
  slices: {
    type: Array,
    required: true,
  },
})

const getSliceClass = (slice: any) => {
  return [
    `content-type--${slice?.Settings?.SliceWidth?.toLowerCase()?.replace('_', '-') || 'layout-width'}`,
    {
      'content-type--has-background': slice?.Settings?.BackgroundColor,
    },
  ]
}

const getSliceComponent = (slice: any) => {
  switch (slice?.__typename?.replace('ComponentSlices', '')) {
    case 'BannerFeed':
      return defineAsyncComponent(() => import(`~layers/app/components/Slices/BannerFeed.vue`))
    case 'BlogFeed':
      return defineAsyncComponent(() => import(`~layers/app/components/Slices/BlogFeed.vue`))
    case 'Buttons':
      return defineAsyncComponent(() => import(`~layers/app/components/Slices/Buttons.vue`))
    case 'Cookiebot':
      return defineAsyncComponent(() => import(`~layers/app/components/Slices/Cookiebot.vue`))
    case 'CopyCollection':
      return defineAsyncComponent(() => import(`~layers/app/components/Slices/CopyCollection.vue`))
    case 'Faq':
      return defineAsyncComponent(() => import(`~layers/app/components/Slices/Faq.vue`))
    case 'Icons':
      return defineAsyncComponent(() => import(`~layers/app/components/Slices/Icons.vue`))
    case 'Jotform':
      return defineAsyncComponent(() => import(`~layers/app/components/Slices/Jotform.vue`))
    case 'MediaFeed':
      return defineAsyncComponent(() => import(`~layers/app/components/Slices/MediaFeed.vue`))
    case 'PageFeed':
      return defineAsyncComponent(() => import(`~layers/app/components/Slices/PageFeed.vue`))
    case 'ProductsFeed':
      return defineAsyncComponent(() => import(`~layers/app/components/Slices/ProductsFeed.vue`))
    case 'ProductsCollectionFeed':
      return defineAsyncComponent(() => import(`~layers/app/components/Slices/ProductsCollectionFeed.vue`))
    case 'RichtextFeed':
      return defineAsyncComponent(() => import(`~layers/app/components/Slices/RichtextFeed.vue`))
    case 'RichtextWithMedia':
    case 'RichtextWithImage':
      return defineAsyncComponent(() => import(`~layers/app/components/Slices/RichtextWithMedia.vue`))
    case 'RichtextWithProduct':
      return defineAsyncComponent(() => import(`~layers/app/components/Slices/RichtextWithProduct.vue`))
    case 'SaldoChecker':
      return defineAsyncComponent(() => import(`~layers/app/components/Slices/SaldoChecker.vue`))
    case 'Spacer':
      return defineAsyncComponent(() => import(`~layers/app/components/Slices/Spacer.vue`))
    case 'SpotlerProductSlider':
      return defineAsyncComponent(() => import(`~layers/app/components/Slices/SpotlerProductSlider.vue`))
    case 'VideoEmbedFeed':
      return defineAsyncComponent(() => import(`~layers/app/components/Slices/VideoEmbedFeed.vue`))
    case 'StoreOpenings':
      return defineAsyncComponent(() => import(`~layers/app/components/Slices/StoreOpenings.vue`))
    default:
      return defineAsyncComponent(() => import(`~layers/app/components/Slices/Unknown.vue`))
  }
}
</script>

<style src="~layers/app/components/Strapi/Slices.css" />
